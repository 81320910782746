<template>
  <div class="outBox">
    <!-- 大图部分 -->
    <div class="top_img">
      <h1>幻想挑战科技，科技启发幻想</h1>
      <p>TiFancy美颜SDK，让幻想不止于此</p>
      <p>
        TiFancy
        APP（IOS版本，Android版本）集成了拓幻科技的部分核心技术，囊括了人脸检测、人脸识别、人脸追踪等底层AI技术以及美颜美型、
        滤镜特效、2D贴纸、人像抠图、绿幕抠图、哈哈镜、一键美颜、脸型微调、美妆美发等丰富的特效。您可以通过点击下方平台按钮，
        识别显示出的二维码，免费下载，轻松体验智能科技带来的精致化娱乐方式！
      </p>
      <div class="top_div" :class="index == 0 ? 'select' : ''">
        <img
          class="img"
          src="@/assets/demo/icon_APP  Store@2x.png"
          alt="APP  Store"
        />
        APP Store
        <div class="ios_code" v-show="index == 0">
          <img src="@/assets/demo/code.jpg" alt="" />
        </div>
      </div>
      <div class="top_div" @mouseenter="index = 1" @mouseleave="index = 0">
        <img
          class="img"
          src="@/assets/demo/icon_Android@2x.png"
          alt="Android"
        />
        Android
        <div class="and_code" v-show="index == 1">
          <img src="@/assets/demo/code.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="conter">
      <!-- 美颜 -->
      <div class="beauty">
        <div class="bea_div">
          <h2>美颜美型</h2>
          <h3>全局美颜，人脸美型</h3>
          <ul>
            <li>
              集成精致的磨皮、美白、粉嫩，鲜明等美颜功能，精准控制美颜特效和全局影像美化
            </li>
            <li>
              大眼、瘦脸、瘦鼻、嘴型、额头、下巴等美型功能，不失真，又能够完美调整脸型
            </li>
          </ul>
        </div>
        <div class="bea_img">
          <img-comparison-slider class="slider-with-custom-handle">
            <img slot="after" width="100%" src="@/assets/demo/美型前.jpg" />
            <img slot="before" width="100%" src="@/assets/demo/美型后.jpg" />
          </img-comparison-slider>
        </div>
      </div>
      <!-- 美妆 -->
      <div class="make">
        <img src="@/assets/demo/美妆美体.png" alt="" />
        <div>
          <h2>美妆美体</h2>
          <h3>实时美妆</h3>
          <p>
            支持口红、腮红、眉毛、眼影、眼线、睫毛、美瞳自由组合，自定义
            色值，调整妆容，实现实时美妆
          </p>
          <h3>智能美体</h3>
          <p>
            通过AI技术对用户全身进行精细调整，瘦身、长腿功能一应俱全，打
            造完美身材
          </p>
        </div>
      </div>
      <!-- 滤镜 -->
      <div class="filter">
        <h2>特效滤镜</h2>
        <div class="filter_div">
          <h3>美颜滤镜</h3>
          <p>
            能够针对人像美颜的特调滤镜，质感细腻，美白红润效果更佳，让你
            时刻拥有好气色
          </p>
        </div>
        <div class="filter_div right">
          <h3>趣味滤镜</h3>
          <p>
            拥有多套趣味滤镜可供选择，实现素描，卡通，胶片，马赛克，油画，
            蜡笔，水晶球，水墨画等效果，使短视频，特效相机等应用更具趣味性
          </p>
        </div>
        <div class="img_div">
          <img :src="bigImg" alt="滤镜" />
          <div class="sel_filter">
            <img
              class="icon"
              :src="showIndex == 0 ? upNo : upDef"
              alt="上"
              @click="add()"
            />
            <div
              v-for="(item, index) in showList"
              :key="index"
              @mousemove="hover(item, item.id)"
              class="imgItem"
            >
              <div
                class="imgBox"
                :class="item.id == hoverIndex ? 'selDiv' : ''"
              >
                <img
                  :src="item.id == hoverIndex ? item.selSrc : item.defSrc"
                  alt=""
                />
              </div>

              <span>{{ item.text }}</span>
            </div>
            <img
              class="icon"
              :src="showIndex == filterAll.length - 3 ? downNo : downDef"
              alt="下"
              @click="minus()"
            />
          </div>
        </div>
      </div>
      <!-- 人脸 -->
      <div class="face">
        <div class="face_div">
          <h2>人脸特效</h2>
          <h3>智能交互，解锁更多有趣玩法</h3>
          <p>
            基于自研的人脸识别和人脸跟踪技术，对106个人脸关键点实时追踪，
            实现AR贴纸、抖动特效、人像抠图、虚拟形象等人脸特效玩法
          </p>
        </div>
        <img src="@/assets/demo/img_demo_shoushi@2x.png" alt="" />
      </div>
    </div>
    <!-- 在用 -->
    <div class="use">
      <div class="use_top">
        <span class="line"></span>
        <span class="text">支持的云服务</span>
        <span class="line"></span>
      </div>
      <div class="use_conter">
        <div
          v-for="(item, index) in imgList"
          :key="index"
          @mouseenter="change(index)"
        >
          <img :class="index == selImg ? 'sel_img' : ''" :src="item" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 滤镜数据
      filterAll: [
        {
          id: 0,
          bigSrc: require("@/assets/demo/滤镜原图.png"),
          defSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜原图--未选中.png"),
          selSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜原图.png"),
          text: "原图",
        },
        {
          id: 1,
          bigSrc: require("@/assets/demo/滤镜粉夏.png"),
          defSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜粉夏--未选中.png"),
          selSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜粉夏.png"),
          text: "粉夏",
        },
        {
          id: 2,
          bigSrc: require("@/assets/demo/滤镜日系.png"),
          defSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜日系--未选中.png"),
          selSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜日系.png"),
          text: "日系",
        },
        {
          id: 3,
          bigSrc: require("@/assets/demo/滤镜牛奶.png"),
          defSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜牛奶--未选中.png"),
          selSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜牛奶.png"),
          text: "牛奶",
        },
        {
          id: 4,
          bigSrc: require("@/assets/demo/滤镜夕阳.png"),
          defSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜暖阳--未选中.png"),
          selSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜暖阳.png"),
          text: "夕阳",
        },
        {
          id: 5,
          bigSrc: require("@/assets/demo/滤镜拍立得.png"),
          defSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜拍立得--未选中.png"),
          selSrc: require("@/assets/demo/拓幻官网滤镜icon_滤镜拍立得.png"),
          text: "拍立得",
        },
      ],
      upDef: require("@/assets/demo/icon_Demo_up_white@2x.png"), //上按钮
      upNo: require("@/assets/demo/icon_Demo_up_gray@2x.png"),
      downDef: require("@/assets/demo/icon_Demo_down_white@2x.png"), //下按钮
      downNo: require("@/assets/demo/icon_Demo_down_gray@2x.png"),
      showList: null, //小图显示列表
      showIndex: 0, //起止
      hoverIndex: 0, //悬停
      bigImg: require("@/assets/demo/滤镜原图.png"),
      // 在用数据
      imgList: [
        require("@/assets/demo/logo_jinshanyun@2x.png"),
        require("@/assets/demo/logo_wangyinyun@2x.png"),
        require("@/assets/demo/logo_tengxunyun@2x.png"),
        require("@/assets/demo/logo_aliyun@2x.png"),
        require("@/assets/demo/logo_qiniuyun@2x.png"),
      ],
      selImg: 2,
      index: 0, //二维码
    };
  },
  created() {
    this.showList = this.filterAll.slice(0, 3);
  },
  methods: {
    //按钮点击事件
    add() {
      if (this.showIndex <= 0) {
        return;
      }
      this.showIndex = this.showIndex - 1;
      this.changShowList();
    },
    minus() {
      if (this.showIndex >= this.filterAll.length - 3) {
        return;
      }
      this.showIndex = this.showIndex + 1;
      this.changShowList();
    },
    changShowList() {
      let end = this.showIndex + 3;
      this.showList = this.filterAll.slice(this.showIndex, end);
    },
    // 鼠标悬停
    hover(item, index) {
      this.hoverIndex = index;
      this.bigImg = item.bigSrc;
    },
    // 在用悬停改变
    change(i) {
      this.selImg = i;
      /*  this.imgList.splice(i, 1);
      this.imgList.splice(2, 0, item);  */
    },
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
.outBox {
  width: 100%;
  font-family: Source Han Sans CN;
  .top_img {
    height: 720px;
    background: url("~@/assets/demo/Demo下载首图.png") no-repeat center;
    background-size: 100%;
    text-align: center;
    color: #ffffff;
    h1 {
      font-size: 44px;
      font-weight: 500;
      padding-top: 161px;
    }
    p {
      width: 998px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 33px auto;
    }
    .top_div {
      width: 160px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #ffffff;
      border-radius: 4px;
      display: inline-block;
      margin-right: 28px;
      font-size: 16px;
      font-weight: 400;
      position: relative;
      img {
        width: 20px;
        margin-right: 4px;
        vertical-align: middle;
      }
      .ios_code,
      .and_code {
        position: absolute;
        top: 46px;
        left: -10px;
        width: 180px;
        height: 180px;
        line-height: 180px;
        background: url("~@/assets/demo/img_demo_frame_erweima@2x.png")
          no-repeat;
        background-size: 180px;
        translate: height 1s;
        img {
          width: 145px;
        }
      }
    }
    .top_div:hover,
    .select {
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #ffffff;
    }
  }
  .conter {
    background: rgba($color: #000000, $alpha: 0.9)
      url("~@/assets/demo/img_decoration@2x.png") no-repeat center;
    background-size: 100% 1340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    h2 {
      font-size: 48px;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
      margin: 38px 0;
    }
    h3 {
      font-size: 38px;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
      margin: 30px 0;
    }
    p {
      width: 420px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
    }

    //美颜
    .beauty {
      width: $width;
      margin: 80px 0;

      .bea_div {
        text-align: left;
        margin-bottom: 53px;
      }
      ul {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        padding-left: 15px;
        li {
          list-style: disc;
        }
      }
      .slider-with-custom-handle {
        /* width: 92.1%; */
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        --handle-size: 30px;
        --handle-opacity: 0.5;
        --handle-opacity-active: 0.5;
      }
    }
    // 美妆
    .make {
      width: $width;
      margin: 80px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 44.5%;
        border-radius: 24px;
        margin-right: 115px;
      }
    }
    // 滤镜
    .filter {
      width: $width;
      margin: 80px 0;
      h2 {
        width: 200px;
        margin: auto;
      }
      .filter_div {
        width: 60%;
        display: inline-block;
        vertical-align: top;
        margin: 50px 0;
      }
      .right {
        width: 40%;
      }
      .img_div {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          border-radius: 24px;
        }
        .sel_filter {
          position: absolute;
          top: 0;
          right: 0;
          width: 10.5%;
          height: 99.3%;
          background: rgba($color: #f5f5f5, $alpha: 0.4);
          border-radius: 0px 24px 24px 0px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;

          .icon {
            width: 20px;
            z-index: 1;
            cursor: pointer;
          }
          .imgItem {
            width: 70.9%;
            margin: auto;
            img {
              width: 100%;
              display: block;
              border-radius: 4px;
              cursor: pointer;
            }
            span {
              display: block;
              font-size: 12px;
              font-weight: 400;
              color: #222;
              line-height: 24px;
            }
          }
          .imgBox {
            padding: 2px;
            border: 1px solid transparent;
            border-color: rgba(151, 151, 151, 0);
          }
          .selDiv {
            border: 1px solid #4c8cf5;
            border-radius: 4px;
          }
        }
      }
    }
    // 人脸
    .face {
      width: $width;
      margin: 80px 0;
      display: flex;
      justify-content: space-around;
      .face_div {
        margin-right: 32px;
        padding-left: 80px;
      }
      img {
        width: 41%;
      }
    }
  }
  // 在用
  .use {
    height: 450px;
    background: #f5f5f5;
    padding-top: 1px;
    .use_top {
      text-align: center;
      margin: 79px 0;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      .text {
        font-size: 30px;
        font-weight: 500;
        color: #222222;
        line-height: 38px;
      }
      .line {
        width: 60px;
        height: 4px;
        background: #cccccc;
      }
    }
    .use_conter {
      width: $width;
      margin: auto;
      display: flex;
      justify-content: space-around;
      div {
        width: 180px;
        height: 180px;
        text-align: center;
        line-height: 180px;
      }
      img {
        width: 140px;
        vertical-align: middle;
        border-radius: 50%;
        box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
        transition: all 1s;
      }
      img:hover,
      .sel_img {
        width: 180px;
        height: 180px;
        box-shadow: 0px 0px 18px 0px rgba(144, 177, 232, 0.46);
      }
    }
  }
}
/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>